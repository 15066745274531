import React, { useEffect } from "react";
import '../Style/about.css';
import Navbar from "./Navbar";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="about">
        <div className="about-img-fi">
          <div className="about-black-opacity"></div>
          <h2>About Us</h2>
        </div>
        <div id="about" className="about-section">
          <div className="about-content">
            <p>
              Sandal Tree Soft is a startup firm. It was started a few months back. We are interested in developing software and hardware that will make human life easier.
              We can develop state-of-the-art software applications that are user-friendly, attractive, and provide high performance. We are interested in developing the following services.
            </p>
            <div className="about-card-container">
              <div className="about-card">
                <h3>Backend APIs</h3>
                <p>
                  We develop APIs using SOAP or REST. We use monolithic or microservices architecture to create high-performance APIs. The response time of our APIs is minimal. We can deploy the APIs on AWS, Azure, GCP, Digital Ocean, Fly, or Heroku.
                </p>
              </div>
              <div className="about-card">
                <h3>Front End Apps</h3>
                <p>
                  The business relies on appealing front-end designs. We develop stylish and attractive UIs using React, Angular, Vue, Vaadin, HTML5, CSS3, and JavaScript.
                </p>
              </div>
              <div className="about-card">
                <h3>SaaS Apps</h3>
                <p>
                  We have developed a parametric design SaaS platform for the apparel industry. We can develop similar SaaS applications for various manufacturing units.
                </p>
              </div>
              <div className="about-card">
                <h3>Jira & Outlook Plugins</h3>
                <p>
                  We can develop Jira and Outlook plugins using Java and JavaScript.
                </p>
              </div>
              <div className="about-card">
                <h3>IoT Solutions</h3>
                <p>
                  IoT can be used in various areas such as Smart Cities, Smart Energy, Smart Retail, Sports & Fitness, Telecom, Wearables, and more. We develop server-side and client-side IoT apps, device dashboards, and mobile apps to interact with devices.
                </p>
              </div>
              <div className="about-card">
                <h3>Smartphone Apps</h3>
                <p>
                  We develop Android and iOS apps using Java, Kotlin, React Native, and Swift. We also create cross-platform apps using Flutter.
                </p>
              </div>
              <div className="about-card">
                <h3>Smart Devices</h3>
                <p>
                  We create innovative apps for smart TVs, smartwatches, and smart wearable glasses to enhance human life.
                </p>
              </div>
              <div className="about-card">
                <h3>AI, ML & DL Solutions</h3>
                <p>
                  We develop AI, ML, and DL solutions for various applications, including tutoring, customer care, healthcare consulting, and HR consulting.
                </p>
              </div>
              <div className="about-card">
                <h3>OpenAI Integration</h3>
                <p>
                  We create APIs that interact with ChatGPT, Bard, and other AI models to automate or simplify activities such as document generation, business proposals, code review, and more.
                </p>
              </div>
              <div className="about-card">
                <h3>Robotics</h3>
                <p>
                  We are working on developing robots for tasks such as garbage collection, driving, coconut tree climbing, and detecting animals to prevent deaths caused by wildlife.
                </p>
              </div>
              <div className="about-card">
                <h3>Energy Saving</h3>
                <p>
                  We are developing methods to generate electricity in simple and innovative ways.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="about" className="about-section">
          <div className="about-content">
          <h1 style={{color: '#002347',textAlign:'center'}}>Our current work</h1>
            <div className="about-card-container">
              <div className="about-card">
                <h3>Agri IoT app</h3>
                <p>
                This app will enable a former to control his farm by mobile app.
                He can get weather, water level, soil analysis, leaf analysis on mobile. He can control the motor from the mobile. We use Java, Spring boot, React, Flutter in this app
                </p>
              </div>
              <div className="about-card">
                <h3>Autonomous Building</h3>
                <p>
                The building takes care of itself. It takes care of cleaning , security, natural disaster.
            It will communicate with required entities to make the building clean, secured and enquirable one.
          We use Java, Flutter, React, ML Models in this project
                </p>
              </div>
              <div className="about-card">
                <h3>Traffic Controller</h3>
                <p>
                The accidents are more comman is this decade. The high speed card make many accidents. Our traffic controller will 
                control the vehicles on road. It will instruct the speed of the vehicles
                </p>
              </div>
              <div className="about-card">
                <h3>Fault detection Systems</h3>
                <p>
                The restaurent, construction, factories required to be monitored. If there any issues , it required to be informed to the respective people.
                Our app will analysis the site and alert the users
                </p>
              </div>
              <div className="about-card">
                <h3>Industry , factory monitoring app</h3>
                <p>
                Some time we need to count the elements in the factory like no of bottles manufactured or any action is completed or not. 
                Our app will analyze the site and will give the info.
                </p>
              </div>
              <div className="about-card">
                <h3>App migration</h3>
                <p>
                We worked on few migration projects. We have exposure to JSP to React migration, Java 8 to Java 17 migration, Spring to Spring boot migration. 
We can do migration work better.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="team" className="team-section">
          <h1>Meet Our Team</h1>
          <div className="team-card-container">
            <div className="team-card">
              <img src={process.env.PUBLIC_URL + "Tejas Lamkhade.jpg"} alt="Team Member 1" />
              <h3>Tejas</h3>
              <p>Java, Spring boot , AWS developer</p>
              <p>He is a Java, Spring boot , AWS developer. He is currently working on Back end APIs.</p>
            </div>
            <div className="team-card">
              <img src={process.env.PUBLIC_URL + "Chinmay Jena.jpg"} alt="Team Member 2" />
              <h3>Chinmay</h3>
              <p>Java and Python Developer</p>
              <p>He is Java and Pythondeveloper. He is currently working on Java, Spring boot and Authentication.</p>
            </div>
            <div className="team-card">
              <img src={process.env.PUBLIC_URL + "Umesh Patil.jpg"} alt="Team Member 2" />
              <h3>Umesh</h3>
              <p>React Developer</p>
              <p>He is a React developer. He currently working on our product in front end development.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
