import React from "react";
import "../Style/footer.css";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { BsLinkedin } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const changeOcc = (e) => {
    window.scrollTo(0, 0);
  };
//update
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section footer-about">
          <h1 className="footer-logo">Sandal Tree Soft</h1>
          <p className="footer-address">
            <FaMapMarkerAlt className="address-icon" />
            <a href="https://maps.google.com/maps/search/HWQX%2B9MM%2C%20Yojna%20Nagar%2C%20Pune%20International%20Airport%20Area%2C%20Lohegaon%2C%20Pune%2C%20Maharashtra%20411014%2C%20India/@18.588450697519843,73.94909597933292,17z?hl=en" target="_blank" rel="noopener noreferrer">
            Yojna Nagar, Pune International Airport Area, Lohegaon, Pune, Maharashtra 411014
            </a>
          </p>
        </div>
        <div className="footer-section footer-links">
          <h3>Quick Links</h3>
          <ul>
            <li><Link onClick={changeOcc} to="/">Home</Link></li>
            <li><Link onClick={changeOcc} to="/about">About</Link></li>
            <li><Link onClick={changeOcc} to="/contact">Contact</Link></li>
            <li><Link onClick={changeOcc} to="/services">Services</Link></li>
          </ul>
        </div>
        <div className="footer-section footer-social">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/sandal-tree-soft">
              <BsLinkedin className="social-icon lin-footer" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/sandaltreesoft/">
              <BsInstagram className="social-icon insta-footer" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sandaltreesoft">
              <ImFacebook2 className="social-icon fb-footer" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Sandal Tree Soft. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
