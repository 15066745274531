import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import "../Style/Navbar.css";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) setIsMenuOpen(false); // Close menu on route change
  }, [location]);

  return (
    <nav className='navbar'>
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo">
          <img className="logo" src="newlogo.png" alt="Logo" />
          <span className="brand-name">Sandal Tree Soft</span>
        </NavLink>
        <div className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
          <NavLink exact to="/" onClick={() => setIsMenuOpen(false)} activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/about" onClick={() => setIsMenuOpen(false)} activeClassName="active">
            About
          </NavLink>
          <NavLink to="/services" onClick={() => setIsMenuOpen(false)} activeClassName="active">
            Services
          </NavLink>
          <NavLink to="/contact" onClick={() => setIsMenuOpen(false)} activeClassName="active">
            Contact
          </NavLink>
        </div>
        <button className="navbar-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <RxCross1 /> : <RxHamburgerMenu />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
