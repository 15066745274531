import React from 'react'

const Error_404 = () => {
  return (
    <>
      {/* <Navbar/> */}
      <div style={{textAlign:"center",fontSize:"3rem",margin:"10rem"}}> Sorry, Page Not Found</div>
    </>
  )
}

export default Error_404