import React, { useEffect, useState,useRef } from "react";
import "../Style/contact.css";
import {FiPhoneCall} from 'react-icons/fi'
import {CgMail} from 'react-icons/cg'
import {FaMapMarkerAlt} from 'react-icons/fa'
import { HiOutlineLogin } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import { BiLock } from "react-icons/bi";
import emailjs from '@emailjs/browser';
// import firebaseDB from '../firebase'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
// import '../Style/login.css'
const Contact = () => {

const[data,setdata]=useState({
  uname:"",
  email:"",
  number:"",
  msg:""
});
const {uname,email,number,msg}=data;
const handleChange = (e) => {
   let {name,value} = e.target;
   setdata({...data,
    [name]:value
  })
}
const form = useRef();

const handleSubmit =  (e)=>{
    e.preventDefault();
    const {name,email,phone,message}=data;
    console.log(name,email,phone,message);

    if( !message||!phone || !email || !name)
    {
      toast.error("Please Enter Something")
      return
    }
    console.log("lol");

    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    console.log("lol");
    if (phone.length<10) {
      toast.error("Enter valid number");
      return
    }
    if (!regex.test(email)) {
      toast.error("Enter valid Email");
      return
    }
    emailjs
    .sendForm('service_e5rxua8', 'template_fcij3is', form.current, {
      publicKey: 'BJLQDLKBIz9O2TIFJ',
    })
    .then(
      () => {
        toast.success("Data Sent Successfully");
        setdata({
        message:"",
        email:"",
        phone:"",
        name:""
        })   
      },
      (error) => {
        toast.success("FAILED, Some Error Occured try again")
      },
    );

}


  return (
    
    <div id="contact">
      <div className="con-img_fi">
        <div className="black_opacity">
      </div>
      <h2> Contact With Us</h2>
      </div>
      <ToastContainer position="top-center"/>
      {/* <div className="map-con"> */}
        {/* -----------------------------------------------Contact and input field container start  -------------------------------- */}
      
      <div className="c-container">
        <div className="con-main-df">
        <div className="contact-details">
        <div className="add_icons">
        <FiPhoneCall className="phone-icon"/>   
        <CgMail className="gmail-icon"/>
        <FaMapMarkerAlt className="map-icon"/>
        </div>
        <div className="address_txt">
        <a href="tel:7305694672"> +91 73056 94672</a>
        <a href={`mailto:info@sandaltreesoft.com`} target="_blank">info@sandaltreesoft.com</a>
        <a href={"https://maps.app.goo.gl/ayKwroRugjzg56bPA"}>Yojna Nagar, International Airport Area, Lohegaon, Pune, Maharashtra 411014</a>
        </div>
        {/* <div className="contact-box"> 
        <h3><FiPhoneCall className="phone-icon"/><a href="tel:7305694672"> +91 73056 94672</a> </h3>
        <h3><CgMail className="gmail-icon"/><a href={`mailto:info@sandaltreesoft.com`} target="_blank">info@sandaltreesoft.com</a></h3>
        <h3>
          <FaMapMarkerAlt href="mailto" className="map-icon"/> 2a, 2nd floor Gat ko 1053, Ganesh nagar Syder park 3,
          Awlahadu road, Wagholi, Pune - 412207
        </h3>
        </div> */}
         </div>
        <div className="hor-con"></div>
        <div className="con-input-field">

        {/* https://forms.gle/CqEE81BpZu1KzQTRA */}

          
        <form
        ref={form}
        onSubmit={handleSubmit}
        // action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSejIZFhCW6hwkYSiQP4_23aNOLXLafuL4hCb3DE9c2BWT3uAQ/formResponse?edit2=2_ABaOnufWGX9_JzN2BgFdy-UvSU6XJPnY1mXIY4S_nVqWS2mYKHLkrCGDZHyvdfGuhg"

>
                            
        <h1>Send us a message</h1>
          <div className="inputs-cont">
          <input type="text"  name="name" value={data.name} onChange={handleChange}   placeholder="Your Name" required/><br/>
          <input type="email" name="email" value={data.email} onChange={handleChange}    placeholder="Your Email" required/><br/>
          <input type="text" name="phone" value={data.phone} onChange={handleChange}  placeholder="Your Mobile No" required/><br/>
          <textarea placeholder=" Your Message" value={data.message} onChange={handleChange}  name="message"   required  ></textarea><br/>
          <button type="submit">Send Message</button>
          </div>
        
        </form>


        </div>
        </div>
        </div>
        {/* -----------------------------------------------Contact details Start -------------------------------- */}
       {/* <div className="contact-box"> <h1></h1>
        <h3><FiPhoneCall className="phone-icon"/> +91 73056 94672 </h3>
        <h3><CgMail className="gmail-icon"/>info@sandaltreesoft.com </h3>
        <h3>
          <FaMapMarkerAlt href="mailto" className="map-icon"/> 2a, 2nd floor Gat ko 1053, Ganesh nagar Saier park 3,
          Awlahadu road, Wagholi, Pune - 412207
        </h3>
        </div> */}
        {/* -----------------------------------------------Contact details End-------------------------------- */}

        {/* -----------------------------------------------Input Field Start-------------------------------- */}
        {/* <div className="con-input-forms">
        <div className="input-main">
        <div className="input-container">
          <section id="input-content">
            <form action="">
              <span className="login-icon">
                {" "}
              </span>
              <h1>Send MSG To US</h1>
              <div>
                <span className="user-icon">
                  <FaUser />
                </span>
                <input
                  type="text"
                  placeholder="Name *"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id="name"
                />
              
              </div>
              <div>
                <span className="lock-icon">
                  <BiLock />
                </span>
                <input
                  type="email"
                  placeholder="Email *"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  id="email"
                />
              </div>
              <div>
                <span className="lock-icon">
                  <BiLock />
                </span>
                <input
                  type="number"
                  placeholder="Mobile Number *"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  id="number"
                />
              </div>
              <div>
                <span className="lock-icon">
                  <BiLock />
                </span>
                <textarea
                  type="textarea"
                  placeholder="Messages *"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  id="message"
                />
              </div>
              <div>
                <input type="submit" value="Send Message" />
                <div className="links">
          
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
        </div> */}
        {/* -----------------------------------------------Input Field End-------------------------------- */}
      <div className="map-iframe">
      <iframe className="iframe"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3781.6802090479173!2d73.94909597933292!3d18.588450697519843!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c408fc73e865%3A0xb760a856e7dbfb6d!2sHWQX%2B9MM%2C%20Yojna%20Nagar%2C%20Pune%20International%20Airport%20Area%2C%20Lohegaon%2C%20Pune%2C%20Maharashtra%20411014!5e0!3m2!1sen!2sin!4v1723371714981!5m2!1sen!2sin"
          width="80%"
          height="700"
          style={{border:0}}
          allowFullScreen="true"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

        </div>
      {/* </div> */}
      {/* </Wrapper> */}


      
    </div>
  );
};

export default Contact;
