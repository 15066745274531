import React from "react";
import "../Style/home.css";
import "animate.css";
import Carousel from 'react-bootstrap/Carousel'
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-content">
        <div className="home-text">
          <h1 className="home-heading">Sandal Tree Soft</h1>
          <h6 className="home-subheading">Our team members are experts in technology.</h6>
          <div className="home-description">We provide high-performance, low-latency applications. Our software solutions cater to any business needs.</div>

          <Link to="/contact" className="home-contact-link">Contact Us</Link>
        </div> 
        <div className="home-image">
          <img src={process.env.PUBLIC_URL + "home_page1.png"} alt="Sandal Tree Soft" className="home-img"/>
        </div>

      </div>
     {/* New sections */}
     <div className="home-section">
        <div className="section-content">
          <div className="section-image">
            <img src={process.env.PUBLIC_URL + "home_page.png"} alt="Web Development" className="section-img" />
          </div>
          <div className="section-text">
            <h2 className="section-heading">Web Development</h2>
            <p className="section-description">We specialize in creating responsive and interactive web applications that cater to your business needs. Our team is proficient in the latest web technologies to ensure your website is fast, secure, and scalable.</p>
          </div>
        </div>
      </div>

      <div className="home-section">
        <div className="section-content">
          <div className="section-text">
            <h2 className="section-heading">App Development</h2>
            <p className="section-description">Our app development team excels in creating high-quality mobile applications for both Android and iOS platforms. We focus on delivering a seamless user experience and robust functionality.</p>
          </div>
          <div className="section-image">
            <img src={process.env.PUBLIC_URL + "app_development.png"} alt="App Development" className="section-img" />
          </div>
        </div>
      </div>

      <div className="home-section">
        <div className="section-content">
          <div className="section-image">
            <img src={process.env.PUBLIC_URL + "ai&ml development.png"} alt="AI & ML" className="section-img" />
          </div>
          <div className="section-text">
            <h2 className="section-heading">Artificial Intelligence and Machine Learning</h2>
            <p className="section-description">We leverage the power of Artificial Intelligence and Machine Learning to create innovative solutions that drive business growth. From predictive analytics to intelligent automation, we can help you harness the full potential of AI and ML.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
